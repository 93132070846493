import { graphql } from "gatsby"
import HomePage from "../views/HomePage"

export const query = graphql`
  query HomePage($images: [String!]!) {
    images: allContentfulAsset(filter: { contentful_id: { in: $images } }) {
      edges {
        node {
          contentful_id
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    mobileImages: allContentfulAsset(
      filter: { contentful_id: { in: $images } }
    ) {
      edges {
        node {
          contentful_id
          fluid(maxWidth: 768, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

export default HomePage
